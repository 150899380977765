import { ITheme } from './iTheme';

export const OaktreeTheme: ITheme = {
    name: 'OaktreeTheme',
    properties: {
        '--loginbackground': 'url(../../../assets/images/background.png)',
        '--logindialogcolor': 'rgba(236, 236, 236, 0.8)',
        '--loginfontcolor': '#000',
        '--buttonfontcolor': '#fff',
        '--secondary-btn-font-clr':'#fff',
        '--primary': '#008265',
        '--secondary': '#ddd9c4',
        '--tertiary': '#1A1A24',
        '--nav': '#fff',
        '--navtext': '#2e2e2e',
        '--navtext-secondary': '#fff',
        '--navbar': '#008265',
        '--navmenuitemselected': '#007359',
        '--navmenuitemselectedborder': '#ddd9c4',
        '--footer': '#ececec',
        '--success': '#06ba06',
        '--warning': '#ff9831',
        '--error': '#d80d0d',
        '--info': '#3c5a99',
        '--font': 'TimesNewRoman',
        '--loginlogo': 'Oaktree-logo.png',
        '--logo': 'Oaktree-logo.png',
        '--footerlogo': 'Oaktree-logo.png',
        '--loginlogowidth': '12rem',
        '--favicon': 'Oaktree-favicon.ico',
        '--logo-title-align': '1.5rem',
        '--reportlogo': 'Oaktree-logo.png',  // Only supports JPEG and PNG formats.
        '--reportfontsize': '11',
        '--reportlogoposistion': 'right',
        '--reportlogoHeight': 50,
        '--font-2': 'TimesNewRoman Bold',
        '--reportHeadingFontSize': '14',
        '--navlogoheight': '25px',
        '--mandatoryJur-cev2' :'#00000',
        '--eligibleJur-cev2':'#00000',
        '--pyElectedInJur-cev2':'#00000',
        '--pyElectedOutJur-cev2':'#00000',
        '--selectionBoxTextColor-cev2':'#000',  
        '--mandatoryInfoboxColor-cev2':'#168736',  
        '--eligibleInfoboxColor-cev2':'#eb4f00',  
        '--pyElectedInInfoboxColor-cev2':'#747480',  
        '--pyElectedOutInfoboxColor-cev2':'#155CB4', 
        '--infoboxMandatoryFontColor-cev2':'#fff',       
        '--isOTPFeatureEnabled': false,
        '--isOTPSecondAuthFactorBypassed': false,            
        '--isMYEYLoginEnabled': true,
        '--isZipCodeSecondFactorEnabled': true,
        '--isTINSecondFactorEnabled': true,
        '--isPartnerCodeSecondFactorEnabled': false
    }
};
