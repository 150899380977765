import { EYTheme } from 'src/app/themes/ey.theme';
import { OaktreeTheme } from 'src/app/themes/oaktree.theme';
import { LandmarkTheme } from 'src/app/themes/landmark.theme';
import { GSTheme } from 'src/app/themes/gs.theme';
import { EYTestTheme } from 'src/app/themes/eyTest.theme';
import { DemoFundsTheme } from 'src/app/themes/demoFunds.theme';

const api = [
  'https://raptorapimq.ey.com/statecomposite/',
  'https://raptorapimq.ey.com/taxdocument/',
  'https://raptorapimq.ey.com/withholding/',
  'https://raptorapimq.ey.com/taxdocdropoff/',
  'https://raptorapimq.ey.com/passthroughentity/'
];

export const environment = {
  production: true,
  setIdleTime: 1720,
  setIdleTimeOut: 60,
  setKeepAlivePing: 10,
  apiEndpoint: api[0],
  documentApiEndpoint: api[1],
  withholdingApiEndpoint: api[2],
  documentdropoffApiEndpoint: api[3],
  pteApiEndPoint:api[4],
  protectedResourceUri: [
    api[0],
    api[1],
    api[2],
    api[3],
    api[4]
  ],
  configExternal: {
    authority: 'https://login.microsoftonline.com/99050bca-65fe-4f6d-8478-388cd894a57f',
    instance: 'https://login.microsoftonline.com/',
    postLogoutRedirectUrl: 'https://raptorscpq.ey.com/',
    tenant: '99050bca-65fe-4f6d-8478-388cd894a57f',
    clientId: '42b171e3-2f5c-489c-9ade-dac68c9f6666',
    redirectUri: window.location.origin,
    scope: ['api://1c1367c0-5488-4791-aa24-46d04ca3e355/user_impersonation'],
    cache: 'sessionStorage',
    extraQueryParameter: { domain_hint: 'eydmz.net' }
  },
  configInternal: {
    authority: 'https://login.microsoftonline.com/5b973f99-77df-4beb-b27d-aa0c70b8482c',
    instance: 'https://login.microsoftonline.com/',
    postLogoutRedirectUrl: 'https://raptorscpq.ey.com/',
    tenant: '5b973f99-77df-4beb-b27d-aa0c70b8482c',
    clientId: '1a1c0661-7706-49f5-8f9b-b90e06802c51',
    redirectUri: window.location.origin,
    scope: ['api://813446a5-2db3-4f04-92ba-dd5136765f3c/user_impersonation'],
    cache: 'sessionStorage',
    extraQueryParameter: { domain_hint: 'ey.net' }
  },
  theme: {
    'RqsJAbVuUdX4tKGurEjNCRZArIs-jQPcW23BdAlzup3zGuhGufX79bZRYK2GXAFf': OaktreeTheme,
    'pHapTzMtffz4ZAC8_L_r6n_0-0JqQB1QLImLTQPfawPfuKQoIDRE3sAA2xCUUNUl': LandmarkTheme,
    'vY4RwDSUJIw5XEvCOpJjHYC3TRHfYJQtExmJNJ9FZLz3606-Xa3O3MclrWvNsAQ2':EYTestTheme,
    '_r_Ag_-n9obautSbNPDYf8-_QSeXjDXjF3pbjfiTA8FeXee21cIJqIJfE1QxF_92':DemoFundsTheme,
    'fBzqByJbBFRLIA488oqzP2GKqurv7VsqBiG4elfVhoIzVz98iM-FmKhMwLGFZRQJ':EYTestTheme,
    'default': EYTheme
  },
  cidGS:'pHapTzMtffz4ZAC8_L_r6n_0-0JqQB1QLImLTQPfawPfuKQoIDRE3sAA2xCUUNUl',
  appInsights: {   
    instrumentationKey: '04ad5f48-683e-4267-b05b-ac8078b316d2',  
    roleName: 'RAPToR-INVP-UI',   
    appId : 'InvestorPortalUILogs', 
    logToConsole : true,
    logToAppInsights : true,
    clickAnalyticsEnabled : false
    },
    enableSingleTenant: true
};
